:root {
  --standard-space: calc(1em / 3);
}

.spinner-message > p {
  display: flex;
  align-items: center;
}

.spinner-message > p > span {
  flex: 1;
}

.spinner-message > p > .Polaris-Spinner {
  margin-right: var(--standard-space);
}

.icon-message > p {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
}

.icon-message > p > span:not(.Polaris-Icon) {
  flex: 1;
}

.icon-message > p > .Polaris-Icon {
  margin-right: var(--standard-space);
}

.icon-message > .Polaris-List {
  /*
  Line the list up with the message by matching the .Polaris-Icon
  width (2rem), offset by the standard space.
  */
  margin-left: calc(2rem + var(--standard-space));
}
